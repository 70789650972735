import React from 'react';
import { ButtonRow } from './styles';
import { MdDeleteForever } from 'react-icons/md';
import { GoPencil } from 'react-icons/go';

interface LojaProps {
  actionDelete(value: any): void;
  actionEdit(value: any): void;
}

export const ButtonContainerModal: React.FC<LojaProps> = ({
  actionDelete,
  actionEdit,
}) => {
  return (
    <>
      <ButtonRow type="button" onClick={actionDelete} title="Excluir">
        <MdDeleteForever size={30} style={{ color: '#e63c3c' }} />
      </ButtonRow>
      <ButtonRow
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="false"
        aria-controls="collapseOne"
        onClick={actionEdit}
        title="Editar"
      >
        <GoPencil size={30} style={{ color: '#72ab90' }} />
      </ButtonRow>
    </>
  );
};
