import styled from 'styled-components';

export const ButtonRow = styled.button`
  flex: 1;
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
`;
