import * as yup from 'yup';

export const schema = yup
  .object({
    inputValue: yup.string().required(),
    infoSelec: yup.object().when('nivel', {
      is: (value: any) => {
        if (value === 3) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
      }),
      otherwise: yup.object().notRequired().nullable(true),
    }),
    cod_id_ctb: yup.object().when('nivel', {
      is: (value: any) => {
        if (value === 3) return true;
        return false;
      },
      then: yup.object().shape({
        label: yup.string().required(),
        value: yup.number().required(),
      }),
      otherwise: yup.object().notRequired().nullable(true),
    }),
  })
  .required();
