import React from 'react';
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { InputPercent } from '~/components/NovosInputs/';

import { Cell, Container } from './styles';

interface LojaProps {
  baseMargem(value: any): void;
  cod_loja: number;
  register: UseFormRegister<FieldValues>;
  errors: any;
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues, any>;
}

export const TableRowModal: React.FC<LojaProps> = ({
  baseMargem,
  cod_loja,
  register,
  errors,
  setValue,
  control,
}) => {
  return (
    <Container>
      <Cell>
        <p>{cod_loja}</p>
      </Cell>
      <Cell>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '0',
              top: '-2.1875rem',
            }}
          >
            <InputPercent
              label=""
              placeholder="0,00"
              min={-99999999}
              max={999999999}
              onInput={(event: any) => {
                setValue(`porcent${cod_loja}`, event.target.value);
                baseMargem(event.target.value);
              }}
              name={`porcent${cod_loja}`}
              register={register}
              control={control}
              isError={!!errors.porcent}
            />
          </div>
        </div>
      </Cell>
    </Container>
  );
};
