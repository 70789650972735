import styled from 'styled-components';
import { Modal as BootstrapModal } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  h3 {
    margin-top: 26px;
    margin-left: 9px;
    margin-bottom: 32px;
  }

  hr {
    margin-left: 10px;
    margin-right: 6px;
    margin-bottom: 10px;
  }

  .ContainerModal {
    padding-left: 18px;
    padding-right: 0px;
  }

  .dataTable {
    max-height: 400px !important;
    width: 100% !important;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .MuiDataGrid-windowContainer {
    max-height: 450px !important;
    min-height: 400px !important;

    .MuiDataGrid-window {
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }

  .MuiDataGrid-renderingZone {
    max-height: 400px !important;
  }
  .super-app-theme--cell {
    text-align: center !important;
    align-items: center !important;
  }
  .actButtonsCredito {
    margin-top: 31px;
    display: flex;
    flex-direction: row;
  }
  .inputButton {
    z-index: 1;
    position: absolute;
    float: left;
    margin-left: -1px;
    height: 39px;
    line-height: 39px;
    border-radius: 3px 0px 0px 3px;
    background-color: ${({ theme: { colors } }) => colors.nav}!important;
  }
  .flags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    @media (min-width: 400px) {
      .flag:nth-child(2) {
        margin-left: 30px;
      }
    }
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
    background-color: transparent !important;
  }
  .MuiDataGrid-root .MuiDataGrid-columnSeparator {
    right: -20px !important;
  }
  .MuiIconButton-label {
    display: none !important;
  }

  .MuiDataGrid-footerContainer {
    display: none !important;
  }
`;

export const ButtonRow = styled.button`
  flex: 1;
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
`;

export const Modal = styled(BootstrapModal)`
  .modal-lg {
    max-width: 50%;
  }
  .modal-dialog-scrollable .modal-body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  .modal-content {
    border-radius: 4px;
    margin-top: 10px;
  }
  .modal-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    .modal-title {
      color: #57069e;
      font-size: 1.2rem;
      /* font-family: 'Nunito Sans'; */
      font-weight: 600;
    }
  }
  .MuiTableCell-head:first-child {
    text-align: center !important;
  }
  .MuiTableCell-head {
    text-align: right !important;
  }
  .modal-body {
    padding: 0px;
    padding-left: 20px;
    padding-right: 20px;
    .display-de-etiquetas {
      margin-top: 10px;
      padding: 0px 10px;
      overflow: auto;
      height: 550px;

      /* width */
      ::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #c7c7c7;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #ababab;

        transition: 0.2s;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #969696;
      }
    }
  }
  .modal-footer {
    padding: 15px 20px;

    border: none;
  }
`;

export const ButtonRowPlus = styled.button`
  flex: 1;
  border: none;
  background-color: transparent !important;
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
  }
`;
