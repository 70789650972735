import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  border-bottom: 1px solid;
  border-color: rgba(224, 224, 224, 1);
`;

export const Cell = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
`;
